export default {
  nl: {
    login: 'Inloggen',
    generalTitle: 'Inloggen in het PGB Portaal',
    copyright: 'PGB Portaal',
    cookiePolicy: 'Cookiebeleid',
    privacy: 'Privacy',
    accessibility: 'Toegankelijkheid',
    home: 'Home',
  },
}
